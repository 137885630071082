import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import Analytics from '../components/Analytics';
import Button from '../components/Button';
import Intro from '../components/Intro';
import Layout from "../components/Layout";
import { ROUTES } from './index';
import React from "react";
import SEO from "../components/Meta";
import { PropTypes } from 'prop-types';

import Tip1 from '../assets/images/tips/tip_1.jpg';
import Tip2 from '../assets/images/tips/tip_2.jpg';
import Tip3 from '../assets/images/tips/tip_3.jpg';
import Tip4 from '../assets/images/tips/tip_4.jpg';
import Tip5 from '../assets/images/tips/tip_5.jpg';
import Tip6 from '../assets/images/tips/tip_6.jpg';

const Tip = ({ title, description, image }) => {
  return (
    <li>
      <img src={image} className="w-full mb-4"/>

      <strong className="block mb-1">
        { title }
      </strong>

      <p>
        { description }
      </p>
    </li>
  )
}

function Tips() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({id: "title_tips", defaultMessage: "Tips"})}
        description={intl.formatMessage({
          id: "description_tips",
          defaultMessage: "Hier vind je alvast enkele tips om je opname nog beter te maken!"})}
        keywords={[`dww`, `de warmste week`, `het warmste koor`, `tips`]}
      />

      <Analytics
        title="Tips"
      />

      <Intro title={intl.formatMessage({id: "tips_title", defaultMessage: "Tips"})}>
        <FormattedMessage
            id="tips_description"
            defaultMessage="Hier vind je alvast enkele tips om je opname nog beter te maken!" />
      </Intro>

      <ul className="container grid gap-8 sm:grid-cols-2 py-section">
        <Tip
          title={intl.formatMessage({id: "tip_1_title", defaultMessage: "Kies jouw ideale locatie"})}
          description={intl.formatMessage({id: "tip_1_description", defaultMessage: "Zoek een kamer met een heldere akoestiek zonder storend achtergrondgeluid. Veel natuurlijk licht is een must, maar vermijd tegenlicht."})}
          image={Tip1} />

        <Tip
          title={intl.formatMessage({id: "tip_2_title", defaultMessage: "Een goeie camera maakt het verschil"})}
          description={intl.formatMessage({id: "tip_2_description", defaultMessage: "De camera van je smartphone is op zich prima, maar misschien heeft je computer een betere webcam of kan je met je fototoestel ook filmpjes maken."})}
          image={Tip2} />

        <Tip
          title={intl.formatMessage({id: "tip_3_title", defaultMessage: "Gebruik de beste microfoon die je in huis hebt"})}
          description={intl.formatMessage({id: "tip_3_description", defaultMessage: "Heb je een externe microfoon of headset om op je toestel aan te sluiten? Hoe helderder je stem te horen is, hoe beter!"})}
          image={Tip3} />

        <Tip
          title={intl.formatMessage({id: "tip_4_title", defaultMessage: "Zorg dat je van het scherm knalt"})}
          description={intl.formatMessage({id: "tip_4_description", defaultMessage: "Film jezelf tegen een rustige, neutrale achtergrond. Draag kleuren die mooi contrasteren met je omgeving."})}
          image={Tip4} />

        <Tip
          title={intl.formatMessage({id: "tip_5_title", defaultMessage: "Maak er eentje van om in te kaderen"})}
          description={intl.formatMessage({id: "tip_5_description", defaultMessage: "Zoek het ideale beeldkader. Niet té close, dat oogt al snel benauwd. Maar zet de camera ook niet te ver: je mag zeker gezien worden."})}
          image={Tip5} />

        <Tip
          title={intl.formatMessage({id: "tip_6_title", defaultMessage: "Test, experimenteer en selecteer"})}
          description={intl.formatMessage({id: "tip_6_description", defaultMessage: "Neem verschillende takes waarin je nieuwe opstellingen, locaties en ideeën uitprobeert. Zo kom je er vanzelf achter wat niet en juist wel werkt."})}
          image={Tip6} />
      </ul>

      <section className="container max-w-2xl text-center my-section">
        <h2 className="sr-only">
          <FormattedMessage
              id="tips_extra_title"
              defaultMessage="Meezingen?" />
        </h2>

        <p className="max-w-md mb-section">
          <FormattedMessage
              id="tips_extra_description"
              defaultMessage="Klaar om mee te zingen? Ken je het nummer al een beetje en heb je 2 toestellen bij de hand?" />
        </p>

        <Button to={ROUTES.STEPS}>
            <FormattedMessage
              id="links_sing_along"
              defaultMessage="Zing mee" />
        </Button>
      </section>
    </Layout>
  );
}

Tip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired
}

export default Tips;
